<div class="container">
  <h3 class="text-primary ab_left_text">Introduction</h3>
  <p>In a significant stride towards combating money laundering and terrorism financing, the innovative Compliance
    Planet Software, invented in 2017 by Prince Efue, the MD/CEO of Efuelite Solutions Limited, has emerged as a
    pioneering solution in the fight against financial crimes. With its unique problem-solving design and approach, the
    software has been acknowledged for its exceptional capabilities in Anti-Money Laundering (AML), Counter-Financing of
    Terrorism (CTF), Countering Proliferating Financing (CPF), Fraud Prevention, Detection and Management, Transaction
    Monitoring, Global Sanction Screening, Customer Risk Assessment Monitoring, Enhance Due Diligence, Adverse Media
    Monitoring, and Automated Regulatory Report Production and Submission.
    <br>
    <br>
    The software was awarded a patent by the office of the President, General Muhammadu Buhari (Rtd), in 2018.
    <br>
    <br>
    The Nigeria Digital Technology sector witnessed drastic improvements in digital connectivity and productivity in
    Anti-Money Laundering, Counter-Terrorism Financing, Countering Proliferating Financing, Fraud Detection, and
    Mitigation amongst Fintech, Commercial Banks, and Microfinance Banks in Nigeria with Compliance Planet Software.
    Furthermore, the positive impact of Compliance Planet extends beyond regulatory compliance. Reducing the need for
    financial institutions to procure AML software from overseas has played a vital role in stabilizing the Nigerian
    economy, particularly regarding forex exchange. Eliminating annual subscriptions and maintenance costs in foreign
    currency has proven advantageous for Fintech and commercial banks in Nigeria, fostering financial stability and
    growth in the sector.

    The software's prowess in Anti-Money Laundering, Counter-Terrorism Financing, and Countering Proliferating Financing
    operations has been instrumental in elevating Nigeria's financial sector to new heights. Its advanced capabilities
    empower financial institutions to proactively detect and mitigate fraudulent activities, safeguarding their clients
    and reputation.
    “The vast and versatile capabilities of the software have recently led to the increased dependency of financial
    institutions on the locally made product Compliance Planet for Combating Money Laundering, Countering Financing of
    Terrorism, and Countering Proliferating Financing operations.”
    <br>
    <br>
    As a career, financial compliance control and analysis has become more interesting as the new invention introduces
    the capabilities of Artificial Intelligence, Machine Learning, Deep Learning, and Natural Language Processing into a
    fully automated workflow,”
  </p>
  <ul>
    <li>
      Currency Transaction Reporting <strong>(CTR FTR)</strong>
    </li>
    <li>
      Suspicious Activity/Transaction Reporting <strong>(STR SAR)</strong>
    </li>
    <li>
      AML/CFT Risk Based Supervision Reporting <strong>(RBS)</strong>
    </li>
    <li>
      Politically Exposed Persons Reporting <strong>(ADRAPS PEP)</strong>
    </li>
    <li>
      Three Tiered Kyc (Know Your Customer) <strong>(ADRAPS KYC)</strong>
    </li>
    <li>
      Foreign Transaction Report <strong>(ADRAPS FTR)</strong>
    </li>
    <li>
      Foreign Account Tax Compliance Act <strong>(FATCA)</strong>
    </li>
    <li>
      Transaction / Activity Monitoring
    </li>

    <li>
      Enhance Due Diligence
    </li>
    <li>
      Global Sanction Screening and Monitoring
    </li>
    <li>
      Institutional Sanction Screening and Monitoring
    </li>
    <li>
      PEP Screening and Monitoring
    </li>
    <li>
      Report Monitoring / Workflow / Analysis
    </li>
    <li>
      Data mining, Deep learning, Artificial Intelligence, Natural Language Processing.
    </li>
    <li>
      Data governance.
    </li>
  </ul>
  <p>In 2018, compliance planet received a <strong>patent award</strong> from the Federal Republic of Nigeria and
    commander-in-chief of the
    armed forces Muhammadu Buhari, GCFR.
    Compliance Planet is <strong>ISO 20700 certified</strong>.</p>

</div>