<!--================Home Banner Area =================-->
<section class="home_banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
      <div class="banner_content text-center">
        <h3>Build Your <span> Business Dreams</span></h3>
        <p>We bridge the digital divide by offering you convenient and reliable solutions guided by the
          principle of low-cost, high value service provision through the application of innovative methods and
          technologies</p>
        <a class="black_btn" href="#services_areas">Discover How</a>
      </div>
    </div>
  </div>
</section>
<!--================End Home Banner Area =================-->

<!--================Services Area =================-->
<section class="services_area p_120" id="services_areas">
  <div class="container">
    <div class="main_title">
      <h2>Our Offered Services</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consecteturadipis icing elit,</p> -->
    </div>
    <div class="row services_inner">
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img src="../assets/img/icon/round_mobile_screen_share_black_48dp.png" alt="Mobile App Development">
          </span>
          <h4>Mobile App Development</h4>
          <p>CEOs, Entrpreneurs call out on us for mobile apps because our scalable tech master piece efficiently
            connect their business to very broad range of target market; with 24hrs support, maintenance and upgrade.
            <a href="#" routerLink="/services/mobile-app-development">
              See More
            </a></p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img src="../assets/img/icon/round_airplay_black_48dp.png" alt="Web App Development">
          </span>
          <h4>Web App Development</h4>
          <p>We build dynamic single page web apps that's super fast and gives you numerous chances to gain more
            clients, help your business
            grow and increase conversion rate. Our web apps auto scale as your business grows.
            <a href="#" routerLink="/services/web-app-development">
              See More
            </a></p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <img src="../assets/img/icon/round_location_city_black_48dp.png" alt="Digital Marketing">
          <h4>Digital Marketing</h4>
          <p>Our dynamic team offers top notch digital marketing, leveraging technology that
            traditional marketing strategies simply can't offer. Get
            a bigger impact with the way consumers interact with your brands online.
            <a href="#" routerLink="/services/digital-marketing">
              See More
            </a></p>
        </div>
      </div>
    </div>
    <div class="row services_inner">
      <div class="col-lg-4">
        <div class="services_item">
          <img src="../assets/img/icon/round_public_black_48dp.png" alt="IT Consultancy">
          <h4>IT Consultancy</h4>
          <p>Maximize the productivity and efficiency of your business;
            be it startup, turn around, re-alignment or an established company. It is essential to have access to the
            right information,
            knowledge, and assistance. We assist both small and large operations prosper even during weak economic
            climate.
            <a href="#" routerLink="/services/it-consultancy">
              See More
            </a></p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <img src="../assets/img/icon/round_table_chart_black_48dp.png" alt="Business Modelling">
          <h4>Business Modelling</h4>
          <p>Our experts excellently tweak the components and functions of your businesses to generates higher
            revenue and profit from diverse operations(online, offline). We use the metric
            gross profit to compare and contrast between models and offers the best
            for your business.
            <a href="#" routerLink="/services/business-modelling">
              See More
            </a></p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <img src="../assets/img/icon/round_meeting_room_black_48dp.png" alt="Training">
          <h4>Training</h4>
          <p>We offer Certified Software Development Course, Software Automated Testing Course; which entails all tools
            required to envision,
            design, build, test, and deploy industrial softwares
            loosely coupled on service oriented architecture using cutting edge technologies.
            <a href="#" routerLink="/services/training">
              See More
            </a></p>
        </div>
      </div>
    </div>

  </div>
</section>
<!--================End Services Area =================-->

<!--================Builder Image Area =================-->
<seciton class="builder_area">
  <div class="main_title">
    <h2>Our Latest Projects</h2>
    <h5>Patented Compliance Planet Anti Money Laundering Software</h5>
  </div>
  <div class="row m0 builder_inner">
    <div class="builder_item">
      <img src="assets/img/cplanet/dashboard.PNG" alt="Compliance Planet">
      <div class="hover">
        
        <p>CASH AND FOREIGN TRANSACTION REPORTING</p>
      </div>
    </div>
    <div class="builder_item">
      <img src="assets/img/cplanet/str.png" alt="Compliance Planet">
      <div class="hover">
        
        <p>SUSPICIOUS TRANSACTION / ACTIVITY REPORTING</p>
      </div>
    </div>
    <div class="builder_item">
      <img src="assets/img/cplanet/analysis1.png" alt="Compliance Planet">
      <div class="hover">
        
        <p>ADRAPS FOREIGN TRANSACTION REPORT</p>
      </div>
    </div>
    <div class="builder_item">
      <img src="assets/img/cplanet/analysis2.PNG" alt="Compliance Planet">
      <div class="hover">
        
        <p>ADRAPS THREE TIERED KNOW YOUR CUSTOMER REPORT</p>
      </div>
    </div>
    <div class="builder_item">
      <img src="assets/img/cplanet/analysis3.PNG" alt="Compliance Planet">
      <div class="hover">
        
        <p>ADRAPS POLITICALLY EXPOSED PERSONS REPORT</p>
      </div>
    </div>
    <div class="builder_item">
      <img src="assets/img/cplanet/analysis4.PNG" alt="Compliance Planet">
      <div class="hover">
        
        <p>ANTI MONEY LAUNDERING / CONTER FINANCIING OF TERRORISM RISK BASED SUPERVISION REPORTS</p>
      </div>
    </div>

    <div class="builder_item">
      <img src="assets/img/cplanet/analysis5.PNG" alt="Compliance Planet">
      <div class="hover">
        
        <p>TRANSACTION / ACTIVITY MONITORING</p>
      </div>
    </div>
    <div class="builder_item">
      <img src="assets/img/cplanet/analysis6.PNG" alt="Compliance Planet">
      <div class="hover">
        
        <p>FOREIGN ACCOUNT TAX COMPLIANCE ACT</p>
      </div>
    </div>
    <div class="builder_item">
      <img src="assets/img/cplanet/analysis1.png" alt="Compliance Planet">
      <div class="hover">
        
        <p>DATA GOVERNANCE, DATA VISUALIZATION, DEEP LEARNING, BUSINESS INTELLIGENCE</p>
      </div>
    </div>
  </div>
</seciton>
<!--================End Builder Image Area =================-->

<!--================Team Area =================-->
<app-expert-team></app-expert-team>
<!--================End Team Area =================-->

<!--================Counter Area =================-->
<app-project-counter></app-project-counter>

<!--================End Counter Area =================-->
<app-media-reference></app-media-reference>
<!--================Feature Area =================-->
<section class="feature_area p_120">
  <div class="container">
    <div class="main_title">
      <h2>Some Features that Makes us Unique</h2>
      <!-- <p>Who are in extremely love with eco friendly system.</p> -->
    </div>
    <div class="row feature_inner">

      <div class="col-lg-4 col-md-6">
        <div class="feature_item">
          <h4><i class="lnr lnr-license"></i>Professional Service</h4>
          <p>Our Professional Services open your business to Unparalleled Expertise, Increased Focus, Risk Management,
            Lower Cost <a href="#" routerLink="/services/professionals">More...</a>.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="feature_item">
          <h4><i class="lnr lnr-phone"></i>Great Support Service</h4>
          <p>Here’s how our great support service team keeps your business in tip-top shape and thus, increasing
            productivity
            for your business.<a href="#" routerLink="/services/support">More...</a>.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="feature_item">
          <h4><i class="lnr lnr-rocket"></i>Technical Skills</h4>
          <p>In reality, though, you should be asking yourself “What should my employees be focusing on? What does my
            business need?”. The answer to that is <a href="#" routerLink="/services/technical">Here...</a></p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--================End Feature Area =================-->

<!--================Testimonials Area =================-->
<!-- <section class="testimonials_area p_120">
  <div class="container">
    <div class="testi_slider owl-carousel">
      <div class="item">
        <div class="testi_item">
          <div class="media">
            <div class="d-flex">
              <img src="../assets/img/testimonials/testi-1.png" alt="">
            </div>
            <div class="media-body">
              <p>Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner,
                speaker, projector, hardware.</p>
              <h4>Mark Alviro Wiens</h4>
              <h5>CEO at Google</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="testi_item">
          <div class="media">
            <div class="d-flex">
              <img src="../assets/img/testimonials/testi-2.png" alt="">
            </div>
            <div class="media-body">
              <p>Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner,
                speaker, projector, hardware.</p>
              <h4>Mark Alviro Wiens</h4>
              <h5>CEO at Google</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--================End Testimonials Area =================-->

<!--================Clients Logo Area =================-->
<!-- <section class="clients_logo_area p_120">
  <div class="container">
    <div class="clients_slider owl-carousel">
      <div class="item">
        <img src="../assets/img/clients-logo/c-logo-1.png" alt="">
      </div>
      <div class="item">
        <img src="../assets/img/clients-logo/c-logo-2.png" alt="">
      </div>
      <div class="item">
        <img src="../assets/img/clients-logo/c-logo-3.png" alt="">
      </div>
      <div class="item">
        <img src="../assets/img/clients-logo/c-logo-4.png" alt="">
      </div>
      <div class="item">
        <img src="../assets/img/clients-logo/c-logo-5.png" alt="">
      </div>
    </div>
  </div>
</section> -->
<!--================End Clients Logo Area =================-->