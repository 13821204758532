<div class="container">
  <h3 class="text-primary ab_left_text">TRANSACTION / ACTIVITY MONITORING
  </h3>

  <blockquote class="generic-blockquote">In mitigating the dangers aligned with financial institutions solely relying
    on manual notification of suspicious transactions and activities, Compliance Planet includes an Artificial
    Intelligence Service that serves as a real time monitor for the institution. This CP-AIS works with well crafted
    policies that are deployed into the system (compliance planet). On breaches to these policies, CP-AIS provides real
    time alert, signals and reports to appropriate channels as configured on compliance planet. This module includes
    features to
  </blockquote><br />
  <ul>
    <li>
      Creating new policies.
    </li>
    <li>
      Revamping existing policies.
    </li>
    <li>
      Activating / Deactivating policies
    </li>
    <li>
      Data Visualization for business intelligence on policies, breaches and their effects on the institution’s system
      in compliance with regulatory standards.
    </li>
    <li>
      BI report on the effects of policies on the system on <strong>who did what; where, when, why and how it happened.</strong>
    </li>
    <li>
      Exporting reports to PDF, XML and Excel
    </li>
  </ul>
  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
        aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
            aria-controls="collapseOne">
            WORKFLOW ARCHITECTURE
          </button>
        </h5>
      </div>

      <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image12.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
  </div>

</div>