<!--================Home Banner Area =================-->
<section class="banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
      <div class="banner_content text-center">
        <div class="page_link">
          <a href="#" routerLink="">Home</a>
          <a href="#" routerLink="/policies/privacy-and-cookie">Privacy And Cookie</a>
        </div>
        <h2>Privacy And Cookie Policy</h2>
      </div>
    </div>
  </div>
</section>
<!--================End Home Banner Area =================-->
<section class="blog_area single-post-area p_120">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 posts-list">
        <div class="ab_left_text">
          <h4>Privacy and Cookie Policy</h4>
          <p>This website is provided and controlled by Efuelite Group LTD. for Efuelite Solutions.</p>
          <blockquote class="generic-blockquote">
            <span class="text-primary">Scope - </span><br />

            Efuelite recognizes and respects the importance of the privacy of our visitors of our websites and users of
            online systems. This policy accounts for the personal data and cookies etc. used via our websites and
            online systems. If you do not agree with the terms herein, please do not use this website or online systems
            and delete the cookies placed by the website.
          </blockquote><br />
          <blockquote class="generic-blockquote">
            <span class="text-primary">Personal data - </span><br />

            On our websites and in the online systems, we collect only those necessary information about name, company,
            postal address, email address, other contact information, age, interests, gender, IP addresses, information
            about your browser, operating system and movements and behavior on our websites and online systems and
            other personal data relevant and necessary for the use of the services. Our websites and online services
            are intended for a general audience, and Efuelite does not knowingly collect personal data about any person
            under the age of 18 years.
            <br /><br />
            We collect and process personal data for the following purposes and based on our legitimate interest and/or
            for fulfilment of a contract as applicable:
            <ul>
              <li>
                Provision of Efuelite services and products according to agreed terms
              </li>
              <li>
                To accommodate your requests and inquiries for services or information
              </li>
              <li>
                Provision of newsletters or notifications, including press releases, offered by Efuelite
              </li>
              <li>
                The purposes listed in the below cookie section
              </li>

            </ul>




            As a global group Efuelite may transfer personal data collected by us on an aggregated or individual level
            to
            various divisions, subsidiaries, joint ventures and affiliated companies of
            (“Efuelite Group”) around the world located inside or outside the African Economic Area for the purposes
            stated above and in accordance with applicable laws, as well to data processors outside of Efuelite
            globally
            for storage and service purposes. Personal data will not be disclosed to anyone outside the Efuelite Group
            unless permitted or required under applicable legislation.
            <br /><br />
            We delete or anonymize your personal data when it is no longer needed for the purposes for which it was
            collected and subsequently processed.
            <br /><br />
            If you have submitted personal data to us, e.g. when registering for a newsletter or a service, you enjoy
            certain rights to gain access to the data processed about you according to applicable regulations. You also
            have the right to object to the processing of your personal data and to rectify the data processed if
            relevant.
            <br /><br />
            If you wish to exercise your right to access your personal data, to object to it being processed or to
            rectify processed data, please contact <a href="tel:info@efuelite.com" target="_blank">info@efuelite.com</a>
            <br /><br />
            We have implemented appropriate organizational and technical security measures to protect your personal
            data. We store personal data on servers with limited access located in secured facilities, and our security
            measures are evaluated on an ongoing basis. The servers are protected by anti-virus software and firewalls,
            among other measures.
          </blockquote><br />
          <blockquote class="generic-blockquote">
            <span class="text-primary">Cookies - </span><br />

            Our websites use cookies. A cookie is a small text file, which is stored on your computer in order to
            recognize your computer at recurring visits. The cookie may transmit information via your browser with a
            purpose of authenticating or identifying the computer (via e.g. the IP address) or the user. Cookies may
            contain information such as registration data and user preferences. When a server receives a request from a
            computer which stores a cookie from the website, the server is able to use the information stored in the
            cookie in combination with the information stored on the server. A cookie cannot collect information from
            your computer and cannot carry any vira or other damaging files.
            <br /><br />
            The following third parties store the following cookies on this website:
            <ul>
              <li>
                Google Analytics and Google DoubleClick (provided by Google Inc.) to enable us a) to perform
                statistical
                analyses of e.g. number of visitors, information on gender, age, location, interests and the like to
                learn
                about our visitors and b) to improve the website friendliness and usability, e.g. on the basis of
                website
                traffic measurements
              </li>
              <li>
                Sumo (provided by Sumo, Inc.) to enable the visitors of our website to use social media features,
                like sharing, sending or recommending pages toothers
              </li>
              <li>
                Youtube (provided by YouTube LLC.) to store session preferences (e.g. language) and suggest other
                content
                based on your previous uses (only activated when you are logged in to Youtube when visiting our website
                and
                only when clicking the YouTube button)
              </li>
              <li>
                Facebook (provided by Facebook, Inc.) to identify users logged in to Facebook for the purpose of
                sharing
                content on Facebook (only when you are logged in to Facebook when visiting our website and only when
                clicking the Facebook button)
              </li>
              <li>
                LinkedIn (provided by LinkedIn Corp.) to enable us a) to enable the “follow” and “share” features of
                LinkedIn (only when you are logged in to LinkedIn when visiting our website and only when clicking the
                LinkedIn button), and b) to perform statistical analyses of information on business demographics to
                learn
                about our visitors
              </li>
            </ul>
            <br /><br />
            You may delete cookies if you no longer wish to store them on your computer. However, please note that the
            high quality of our website may be reduced as a consequence thereof.
            <ul>
              <li>
                For Internet Explorer, a help guide is available <a href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
                  target="_blank">here</a>
              </li>
              <li>
                For Mozilla, a help guide is available <a href="http://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  target="_blank">here</a>
              </li>
              <li>
                For Chrome, a help guide is available <a href="http://support.google.com/chrome/bin/answer.py?hl=en&answer=95647"
                  target="_blank">here</a>
              </li>
              <li>
                For Safari, a help guide is available <a href="https://support.apple.com/kb/ph21411?locale=en_US"
                  target="_blank">here</a>
              </li>
            </ul>

          </blockquote><br />
          <blockquote class="generic-blockquote">
            <span class="text-primary">Changes - </span><br />

            We reserve the right in our sole discretion to amend this privacy and cookie policy at any time without
            providing prior notice to you. We will post at any time current version of this privacy and cookie
            policy on the website
          </blockquote><br />
          <blockquote class="generic-blockquote">
            <span class="text-primary">Contact details - </span><br />

            If you have any questions about this Privacy Policy or other questions regarding protection of your
            personal data please contact <a href="tel:info@efuelite.com" target="_blank">info@efuelite.com</a>
          </blockquote><br />
          <blockquote class="generic-blockquote">
            <span class="text-primary">Law and jurisdiction - </span><br />

            These terms shall be subject to Nigeria law and any dispute, claim, matter of construction or
            interpretation
            arising out of or relating to the website, including these terms, shall be subject to the exclusive
            jurisdiction of the Nigeria courts.
            <br /><br />
            This Privacy Policy was last updated on 16 November 2018
          </blockquote>
        </div>
      </div>
      <div class="col-lg-4">
        <app-right-side-quick-link></app-right-side-quick-link>
      </div>
    </div>
  </div>
</section>


<!--================Team Area =================-->
<app-expert-team></app-expert-team>
<!--================End Team Area =================-->