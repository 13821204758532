import { Component } from '@angular/core';

@Component({
  selector: 'app-media-reference',
  templateUrl: './media-reference.component.html',
  styleUrls: ['./media-reference.component.css']
})
export class MediaReferenceComponent {

}
