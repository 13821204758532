<div class="container">
  <h3 class="text-primary ab_left_text">FOREIGN ACCOUNT TAX COMPLIANCE ACT (FATCA)
  </h3>

  <blockquote class="generic-blockquote">Compliance Planet upon schedule scans the institution’s core banking system
    for accounts held by U.S. taxpayers in accordance with FATCA; Prepares and export report to XML format; then begin
    the data encryption process according to the International Data Exchange Service (IDES).
  </blockquote><br />

  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
      aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
            aria-controls="collapseOne">
            WORKFLOW ARCHITECTURE
          </button>
        </h5>
      </div>

      <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image13.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
  </div>
</div>