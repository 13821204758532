<div class="container">
  <h3 class="text-primary ab_left_text">ADRAPS FOREIGN CURRENCY TRANSACTION REPORT(ADRAPS FTR)
  </h3>
  <p>ADRAPS FTR Report Operations on Compliance Planet
  </p>
  <ul>
    <li>
      Generating Reports
    </li>
    <li>
      Exporting reports to XML and Excel
    </li>
    <li>
      Data Visualization for Business Intelligence
    </li>
  </ul>
  <blockquote class="generic-blockquote">ADRAPS FTR data for all financial products handling foreign transactions are
    continually spooled routinely off peak business operations. Compliance Planet takes between 5 secs - 3 mins to
    generate and export weekly ADRAPS FTR data for any or all of the ADRAPS FTR transaction channels. If reports are
    exported to excel for maybe manual data governance, Compliance Planet has the feature plugin to accept uploaded
    ADRAPS FTR excel files and convert them to XML for upload at the CBN portal.
  </blockquote><br />

  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
        aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
            aria-controls="collapseOne">
            WORKFLOW ARCHITECTURE
          </button>
        </h5>
      </div>

      <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image1.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapsePTwo" aria-expanded="false"
        aria-controls="collapseTwo">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsePTwo"
            aria-expanded="false" aria-controls="collapseTwo">
            Compliance Planet ADRAPS FTR Report Operation Interface
          </button>
        </h5>
      </div>
      <div id="collapsePTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image7.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>

  </div>

  <br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Compliance Planet ADRAPS FTR Channels</span><br />
    CHEQUES, FXFT (FOREIGN EXCHANGE FOREIGN TRANSACTION), INTERNAL TRANSFER,
    CASH DEPOSITS, CASH WITHDRAWAL
  </blockquote>

</div>