import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogConfig, MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
@Component({
  selector: 'app-dialog-advert',
  templateUrl: './dialog-advert.component.html',
  styleUrls: ['./dialog-advert.component.css']
})
export class DialogAdvertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogAdvertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
    const matDialogConfig = new MatDialogConfig()

    matDialogConfig.position = { right: '10px', bottom: '10px' }
    this.dialogRef.updatePosition(matDialogConfig.position)
  }
  onCloseConfirm() {
    this.dialogRef.close(true);
  }
  onCloseCancel() {
    this.dialogRef.close(false);
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
