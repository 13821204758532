<div class="blog_right_sidebar">
  <aside class="single_sidebar_widget post_category_widget">
    <h4 class="widget_title">QUICK MENU</h4>
    <ul class="list cat-list">
      <li>
        <a href="#" routerLink="/products/compliance-planet/introduction" class="d-flex justify-content-between">
          <p>INTRODUCTION</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/currency-foreign-transaction-report-CTR-FTR" class="d-flex justify-content-between">
          <p>CTR FTR</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/suspicious-transaction-activity-report-STR-SAR" class="d-flex justify-content-between">
          <p>STR SAR</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/adraps-foreign-transaction-report-ADRAPS-FTR" class="d-flex justify-content-between">
          <p>ADRAPS FTR</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/adraps-politically-exposed-person-report-ADRAPS-PEP" class="d-flex justify-content-between">
          <p>ADRAPS PEP</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/adraps-3-tiered-know-your-customer-report-ADRAPS-KYC" class="d-flex justify-content-between">
          <p>ADRAPS KYC</p>
        </a>
      </li>

      <li>
        <a href="#" routerLink="/products/compliance-planet/anti-money-laundering-combating-financing-of-terrorism-risk-base-supervision-report-AML-CFT-RBS" class="d-flex justify-content-between">
          <p>AML / CFT RBS</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/foreign-account-tax-compliance-act-FATCA" class="d-flex justify-content-between">
          <p>FATCA</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/transaction-activity-monitoring" class="d-flex justify-content-between">
          <p>TRANSACTION / ACTIVITY MONITORING</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/data-panel" class="d-flex justify-content-between">
          <p>DATA PANEL</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/service-manager" class="d-flex justify-content-between">
          <p>SERVICE MANAGER</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/security-manager" class="d-flex justify-content-between">
          <p>SECURITY MANAGER</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/task-manager" class="d-flex justify-content-between">
          <p>TASK MANAGER</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/file-dock" class="d-flex justify-content-between">
          <p>FILE DOCK</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/system-requirements" class="d-flex justify-content-between">
          <p>SYSTEM REQUIREMENTS</p>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/products/compliance-planet/installations" class="d-flex justify-content-between">
          <p>INSTALLATION</p>
        </a>
      </li>
    </ul>
    <!-- <div class="br"></div> -->
  </aside>
</div>