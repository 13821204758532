<section class="counter_area">
    <div class="container">
        <div class="main_title">
            <h2>Accomplishments</h2>
            <p>Some accomplishments of the Patented Compliance Planet Anti-Money Laundering Software.</p>
        </div>
        <div class="row m0 counter_inner">
            <div class="counter_item">
                <h4 class="counter">594</h4>
                <p>AML/CFT/CPF Projects Completed</p>
            </div>
            <div class="counter_item">
                <h4 class="counter">5</h4>
                <p>Happy Clients</p>
            </div>

            <div class="counter_item">
                <h4 class="counter">11009</h4>
                <p>Detected and Prevented Fraud</p>
            </div>
            <div class="counter_item">
                <h4 class="counter">212071</h4>
                <p>Detected Suspicious Transactions</p>
            </div>
            <div class="counter_item">
                <h4 class="counter">5 billion+</h4>
                <p>Total Monitored Transactions</p>
            </div>
        </div>

    </div>
</section>