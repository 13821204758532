const Dev_General_Service_Core_API: string = 'http://localhost:5000/api/';
const Dev_General_Service_Core_Test_API: string = 'http://localhost/EfueliteGeneralAPI/api/';
const Dev_Server_General_Service_Core_Test_API: string = 'http://192.168.0.106:8090/api/';
const Prod_General_Service_Core_API: string = 'https://efuelitegroupservice.efuelite.com/api/';
// const Dev_Signal_Core_API: string = 'http://localhost:5000/';
// const Dev_Signal_Core_Test_API: string = 'http://localhost/ComplianceSignalServerCore/';
// const Prod_Signal_Core_API: string = 'http://172.27.9.113/ComplianceSignalServerCore/';
// const Prod_Signal_Core_Test_API: string = 'http://172.27.9.113/ComplianceSignalServerCore_Test/';

// const ExcelDir: string = 'ExcelFiles/';


//important pointers to deployment=======================================================================
const General_Service: string = Prod_General_Service_Core_API;
// const Legacy_Service: string = Prod_Legacy_Service_API;
// const Signal_Service: string = Prod_Signal_Core_API;
//======================================================================================================= 
//export const SignalCoreURL: string = Signal_Service + 'updownloadhub';
//=======================================================================================================

//export const SecretPhrase: string = '2f77668a9dfbf8d5848b9eeb4a7145ca94c6ed9236e4a773f6dcafa5132b2f91';



export const Captcha_Site_Key = '6Lda3XsUAAAAACDvUuCal012Nnb55tI8RBUvuxKo';
export const Captcha_Secret_Key = '6Lda3XsUAAAAADRQrv3BAMVw-4w825X8ghloI01T';
export const Captcha_User_Verification_Url = 'https://www.google.com/recaptcha/api/siteverify'


export const UrlContactUS: string = General_Service + 'contactus/sendmail';
export const UrlMarquee_Get: string = General_Service + 'marquee/getmarquee';
export const Url_CountryList: string = General_Service + 'generic/countrylist';
export const Url_Device_Property_Save: string = General_Service + 'deviceproperty/save';
export const UrlAdvert_Get: string = General_Service + 'advert/getadvert';


