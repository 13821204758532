
<!-- <h2 mat-dialog-title>EFUELITE SOLUTIONS</h2> -->
<!-- <hr> -->
<!-- <mat-divider></mat-divider> -->
<div class="alertDialogContent">
    <button class="close" mat-button (click)="onNoClick()">X</button>
    <mat-dialog-content [innerHTML]="data">
    </mat-dialog-content>
</div>
<!-- <mat-divider></mat-divider> -->
<!-- <mat-dialog-actions>

        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        <div class="row">
            <div class="col">
                <button mat-raised-button color="accent" tabindex="-1" (click)="onCloseCancel()">CANCEL</button>
            </div>
            <div class="col onCloseConfirm">
                <button mat-raised-button color="primary" tabindex="-1" (click)="onCloseConfirm()">DISMISS</button>
            </div>
        </div>

    </mat-dialog-actions> -->
<!-- </div> -->