import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogAdvertComponent } from '../Dialogs/dialog-advert/dialog-advert.component';
import { DialogConfirmComponent } from '../Dialogs/dialog-confirm/dialog-confirm.component';
import { IResp } from '../Interfaces/iresp';
import * as Constant from './Constant';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  Advert = {} as IResp;
  constructor(public dialog: MatDialog, private http: HttpClient) { }

  showConfirm(message: string): Promise<boolean> {
    let dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: message
    });
    return dialogRef.afterClosed().toPromise();
  }
  getAdvert() {
    var CanDisplayAdvert = sessionStorage.getItem('d_a');
    if (CanDisplayAdvert != '0') {
      let uri = Constant.UrlAdvert_Get;
      let params = new HttpParams()
        .set("subsidiary", "EfueliteSolutions")

      this.http.get<IResp>(uri, { params: params }).subscribe(R => {
        if (R.actionStatus) {
          var StringSplit: string[] = R.actionMessage.split('~');
          var AdsExpDate: Date = new Date(StringSplit[1]);
          var CurrentDate: Date = new Date();
          var AdsExpYear = AdsExpDate.getFullYear();
          var AdsExpMonth = AdsExpDate.getMonth() + 1;
          var AdsExpDay = AdsExpDate.getDate();
          var CurrentYear = CurrentDate.getFullYear();
          var CurrentMonth = CurrentDate.getMonth() + 1;
          var CurrentDay = CurrentDate.getDate();

          if (new Date(CurrentYear, CurrentMonth, CurrentDay) <= new Date(AdsExpYear, AdsExpMonth, AdsExpDay)) {
            setTimeout(() => {
              this.showAdvert(StringSplit[0]).then(R => {
                // sessionStorage.setItem('d_a', '0'); 
              });
            }, 60000);
          }
        }
      });
    }
  }
  showAdvert(message: string): Promise<boolean> {
    let dialogRef = this.dialog.open(DialogAdvertComponent, {
      hasBackdrop: false,
      data: message
    });
    return dialogRef.afterClosed().toPromise();
  }
}
