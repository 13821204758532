<div class="container">
  <h3 class="text-primary ab_left_text">SUSPICIOUS TRANSACTION / ACTIVITY REPORT (STR/SAR)
  </h3>

  <blockquote class="generic-blockquote">Compliance Planet handles STR / SAR under four operation desks as described
    below.
  </blockquote><br />

  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
      aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
            aria-controls="collapseOne">
            WORKFLOW ARCHITECTURE
          </button>
        </h5>
      </div>

      <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image15.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
  </div>

  <br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">NOTIFICATION DESK -</span><br />
    This desk in compliance planet is opened to all staffs of the institution; irrespective of
    their department; to report any suspicious activity or transaction (tellers etc). Notifications reported via this
    desk are sent to compliance team (revision desk).

  </blockquote>
  <br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">REVISION DESK - </span><br />
    This desk in compliance planet resides within the compliance team. On this desk, the revision
    designated compliance officers thoroughly investigate the notification to ascertain its validity, they classify it
    to be either STR or SAR and further obtain permission to report it as well as retrieve the involved customer’s
    account mandate; and also state the reason for report and action taken. They go ahead to approve the notification
    which is further sent to the Supervision Desk if classified as STR and report desk if classified as SAR. Upon
    disapproval, this notification life process ends on the Revision desk. Compliance Planet has an auto templating
    feature that assist the revision officers in generating report reasons that meets the required specification of the
    NFIU.

  </blockquote>
  <br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">SUPERVISION DESK - </span><br />
    This desk in compliance planet resides within the compliance team. On this desk, the supervision
    designated compliance officers thoroughly investigate this revised str notification to ascertain its validity and
    either approve or disapprove it. Upon approval, it goes to the report desk and upon disapproval, it goes back to
    the revision desk(here the revision officer sees the reason for rejection and either re-process and re-approve or
    do otherwise).
  </blockquote>
  <br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">REPORT DESK - </span><br />
    This desk in compliance planet resides within the compliance team. On this desk, the report
    designated compliance officers thoroughly investigate the supervised and revised notification to ascertain its
    validity. Upon acceptance, the report officer proceeds to add report persons for SARs and then Scan and add related
    transactions for STRs. Report officer finally export the report to XML for upload on the NFIU GoAML portal.

  </blockquote>

</div>