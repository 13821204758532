<!--================Header Menu Area =================-->
<header class="header_area">
  <div class="top_menu row m0">
    <div class="container">
      <div class="float-left">
        <ul class="list header_social">
          <li><a href="#" href="https://web.facebook.com/efuelitesolutions" target="_blank"><i
                class="fa fa-facebook"></i></a></li>
          <li><a href="#" routerLink=""><i class="fa fa-twitter"></i></a></li>
          <li><a href="#" routerLink=""><i class="fa fa-dribbble"></i></a></li>
          <li><a href="#" routerLink=""><i class="fa fa-behance"></i></a></li>
          <li><i class="fa fa-envelope-o"></i>&nbsp; <a href="mailto:solutions@efuelite.com">solutions@efuelite.com</a>
          </li>
          <li> <i class="fa fa-phone"></i><a href="tel:+2348165559818" rel="nofollow">+234 (0) 816-555-9818</a> </li>
          <li> <i class="fa fa-phone"></i><a href="tel:+2348139389009" rel="nofollow">+234 (0) 813-938-9009</a> </li>
        </ul>


      </div>
      <div class="float-right">
        <select class="lan_pack">
          <option value="1">English</option>
          <option value="1">Bangla</option>
          <option value="1">Indian</option>
          <option value="1">Aus</option>
        </select>
        <a class="dn_btn" target="_blank" href="https://tawk.to/chat/5786aeb3c0b96c2d2245409a/default/">Free
          Consultation!</a>
      </div>
    </div>
  </div>

  <div class="main_menu">
    <nav class="navbar navbar-expand-lg navbar-light navbar-fixed-top">
      <div class="container">
        <ng-progress #progressBar [meteor]="options.meteor" [color]="options.color" [min]="options.min"
          [speed]="options.speed" [spinner]="options.spinner" [max]="options.max"
          [spinnerPosition]="options.spinnerPosition" [direction]="options.direction" [thick]="options.thick"
          [trickleSpeed]="options.trickleSpeed" [ease]="options.ease" (started)="onStarted()"
          (completed)="onCompleted()">
        </ng-progress>
        <!-- Brand and toggle get grouped for better mobile display -->
        <a class="navbar-brand logo_h" href="#" routerLink=""><img height="70px;" width="180px;"
            src="../assets/img/EfueliteSolutions_Logo.png" alt="Efuelite Solutions Limited."></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
          <ul class="nav navbar-nav menu_nav ml-auto">
            <li class="nav-item active"><a class="nav-link" href="#" routerLink="">Home</a></li>
            <li class="nav-item"><a class="nav-link" href="#" routerLink="/about-us">About</a></li>
            <li class="nav-item submenu dropdown">
              <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">Services</a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a class="nav-link" href="#" routerLink="/services/mobile-app-development">Mobile
                    App
                    Development</a>
                <li class="nav-item"><a class="nav-link" href="#" routerLink="/services/web-app-development">Web App
                    Development</a>
                <li class="nav-item"><a class="nav-link" href="#" routerLink="/services/digital-marketing">Digital
                    Marketing</a>
                <li class="nav-item"><a class="nav-link" href="#" routerLink="/services/it-consultancy">IT
                    Consultancy</a>
                <li class="nav-item"><a class="nav-link" href="#" routerLink="/services/business-modelling">Business
                    Modelling</a>
                <li class="nav-item"><a class="nav-link" href="#" routerLink="/services/training">Training</a>
              </ul>
            </li>
            <li class="nav-item submenu dropdown">
              <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">Products</a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a class="nav-link" href="#" routerLink="/products/compliance-planet">Compliance
                    Planet</a></li>
              </ul>
            </li>

            <li class="nav-item"><a class="nav-link" href="#" routerLink="/careers">Careers</a></li>
            <li class="nav-item"><a class="nav-link" href="#" routerLink="/contact-us">Contact</a></li>
            <li class="nav-item submenu dropdown">
              <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">Policies</a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a class="nav-link" href="#" routerLink="/policies/privacy-and-cookie">Privacy and
                    Cookie</a>
              </ul>
            </li>
            <!-- <li class="nav-item"><a class="nav-link" href="http://webmail.efuelite.com" target="_blank">Mail</a></li> -->
          </ul>
          <!-- Search Form Area Start if its time to use search, create a function that calls it to appear and another that dissapears the box
          just encapsulate the jquery funcs doing that now into a separate func in activejs and then call them from anywhere as needed -->
          <!-- <ul class="search-form-area animated">
            <div class="search">
              <span id="searchformbtn" class="fa fa-search"> -->
          <!-- <input style="color:black" type="search" name="search" id="search" placeholder="Type keywords &amp; hit enter">-->
          <!-- <button type="submit" ><img src="../assets/img/search-icon.png" alt="Search"></button> -->
          <!-- </span>
            </div>
          </ul> -->
          <!-- <ul class="nav navbar-nav navbar-right">
            <li id="search-btn" class="nav-item"><a href="javascript:0" class="search"><i
                  class="lnr lnr-magnifier"></i></a></li>
          </ul> -->

        </div>
      </div>
    </nav>
    <!-- <div [innerHTML]="gcsesearch"></div> -->
    <div *ngIf="Marquee !=null && Marquee !=''" style="background-color:#fab268;color:rgb(4, 4, 49);font-weight: bold;">
      <marquee [innerHTML]="Marquee">
      </marquee>
    </div>
  </div>
</header>
<div style="margin-top: 100px;"></div>

<!--================Header Menu Area class="google-media-search"=================-->
<router-outlet></router-outlet>
<!--================Clients Logo Area =================-->
<section class="clients_logo_area p_120">
  <div class="container">
    <div class="main_title">
      <h2>Our Esteemed Clients</h2>
      <!-- <p>Who are in extremely love with eco friendly system.</p> -->
    </div>
    <div class="row">
      <div class="item col-lg-2">
        <a href="https://globusbank.com/" target="_blank">
          <img src="../assets/img/clients-logo/Globus_Logo.svg" alt="Globus Bank" height="50px" width="150px">
        </a>
      </div>
      <div class="item col-lg-2">
        <a href="https://www.fcmb.com/" target="_blank">
          <img src="../assets/img/clients-logo/fcmb_logo.jpeg" alt="First City Monument Bank" height="50px"
            width="150px">
        </a>
      </div>
      <div class="item col-lg-2">
        <a href="https://www.accionmfb.com/" target="_blank">
          <img src="../assets/img/clients-logo/Accion_Mfb_logo.png" alt="Accion Micro Finance Bank" height="50px"
            width="150px">
        </a>
      </div>
      <div class="item col-lg-2">
        <a href="https://wemabank.com/" target="_blank">
          <img src="../assets/img/clients-logo/wema_bank.jpeg" alt="Wema Bank" height="50px" width="150px">
        </a>
      </div>
      <div class="item col-lg-2">
        <a href="https://optimusbank.com/" target="_blank">
          <img src="../assets/img/clients-logo/Optimus_Logo.svg" alt="Optimus Bank" height="50px" width="150px">
        </a>
      </div>
      <div class="item col-lg-2">
        <a href="https://efuelite.com/" target="_blank">
          <img src="../assets/img/clients-logo/EfueliteGroup_Logo_trans.png" alt="Efuelite Group" height="50px"
            width="150px">
        </a>
      </div>
    </div>
  </div>
</section>
<!--================End Clients Logo Area =================-->
<!--================ start footer Area  =================-->
<footer *ngIf="Device.isDesktop" class="footer-area p_50">
  <div class="container">
    <div class="row">
      <div class="col-lg-2  col-md-4 col-sm-4">
        <div class="single-footer-widget ab_wd">
          <!--THIS IS TO PERFECTLY ALIGN FOOTER TEXT-->
        </div>
      </div>
      <div class="col-lg-3  col-md-6 col-sm-6">
        <div class="single-footer-widget ab_wd">
          <h6 class="footer_title">About Us</h6>
          <p>Efuelite Solutions is an Independent Consultancy Service, Software Manufacturing Company that provides
            professional Information
            Technology Services to Companies. Having been in operation since 2006 <a href="#"
              routerLink="/about-us">Read
              More...</a></p>
        </div>
      </div>
      <div class="col-lg-2  col-md-4 col-sm-4">
        <div class="single-footer-widget ab_wd">
          <!--THIS IS TO PERFECTLY ALIGN FOOTER TEXT-->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget contact_wd">
          <h6 class="footer_title">Contact Us</h6>
          <p>HEAD OFFICE: No 38 Omolara Olusi Street, Hopeville Estate,
            Sango Tedo
            Lagos State</p>
          <a href="tel:+2348165559818">+234 (0) 816-555-9818</a>
          <a href="tel:+2348139389009">+234 (0) 813-938-9009</a>
        </div>
      </div>
      <div class="col-lg-2  col-md-4 col-sm-4">
        <div class="single-footer-widget ab_wd">
          <!--THIS IS TO PERFECTLY ALIGN FOOTER TEXT-->
        </div>
      </div>
      <!-- <div class="col-lg-5 col-md-6 col-sm-6 offset-lg-1">
        <div class="single-footer-widget">
          <h6 class="footer_title">Newsletter</h6>
          <p>You can trust us. we only send promo offers, not a single spam.</p>
          <div id="mc_embed_signup">
            <form target="_blank" action="" method="get" class="subscribe_form relative">
              <div class="input-group d-flex flex-row">
                <input name="EMAIL" placeholder="Your email address" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email Address '"
                  required="" type="email">
                <button class="btn sub-btn"><span class="lnr lnr-arrow-right"></span></button>
              </div>
              <div class="mt-10 info"></div>
            </form>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row footer-bottom d-flex justify-content-between align-items-center">
      <div class="col-lg-3 col-md-3">
        <!--THIS IS TO PERFECTLY ALIGN COPYRIGHT TEXT-->
      </div>
      <p class="col-lg-6 col-md-6 footer-text m-0" style="text-align:center">


        Copyright &copy; {{Year}} Web App Powered by <a href="http://solutions.efuelite.com" target="_blank">EFUELITE
          SOLUTIONS LTD.</a>
      </p>
      <div class="col-lg-3 col-md-3 footer-social">
        <a href="https://web.facebook.com/efuelitesolutions" target="_blank"><i class="fa fa-facebook"></i></a>
        <a href="#" routerLink=""><i class="fa fa-twitter"></i></a>
        <a href="#" routerLink=""><i class="fa fa-dribbble"></i></a>
        <a href="#" routerLink=""><i class="fa fa-behance"></i></a>
      </div>
    </div>
  </div>

  <a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
  <!-- <div id="cookieConsent">
    Our Cookie Policy
    We use cookies for statistical analysis and to improve the friendliness and usability of our website. By visiting
    our
    website, you consent to our and third party use of cookies as described in our privacy and cookie policy. <a href="#"
      routerLink="/policies/privacy-and-cookie" target="_blank">More info</a>. <a class="cookieConsentOK">That's Fine</a>
  </div> -->
</footer>
<footer *ngIf="Device.isTablet" class="footer-area p_30">
  <div class="container">

    <div class="row footer-bottom d-flex justify-content-between align-items-center">
      <p class="col-lg-1 col-md-1 footer-text m-0 text-center"></p>
      <p class="col-lg-10 col-md-10 footer-text m-0 text-center">
        Copyright &copy; {{Year}} App Powered by <a href="http://solutions.efuelite.com" target="_blank">EFUELITE
          SOLUTIONS LTD.</a>
      </p>
      <p class="col-lg-1 col-md-1 footer-text m-0 text-center"></p>
    </div>
  </div>

  <a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
  <!-- <div id="cookieConsent">
    Our Cookie Policy
    We use cookies for statistical analysis and to improve the friendliness and usability of our website. By visiting
    our
    website, you consent to our and third party use of cookies as described in our privacy and cookie policy. <a href="#"
      routerLink="/policies/privacy-and-cookie" target="_blank">More info</a>. <a class="cookieConsentOK">That's Fine</a>
  </div> -->
</footer>
<footer *ngIf="Device.isMobile" class="footer-area p_20">
  <div class="container">

    <div class="row footer-bottom d-flex justify-content-between align-items-center">
      <p class="col-lg-1 col-md-1 footer-text m-0 text-center"></p>
      <p class="col-lg-10 col-md-10 footer-text m-0 text-center">
        Copyright &copy; {{Year}}
        App Powered by <a href="http://solutions.efuelite.com" target="_blank">EFUELITE SOLUTIONS LIMITED.</a>
      </p>
      <p class="col-lg-1 col-md-1 footer-text m-0 text-center"></p>
    </div>
  </div>

  <a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
  <!-- <div id="cookieConsent">
    Our Cookie Policy
    We use cookies for statistical analysis and to improve the friendliness and usability of our website. By visiting
    our
    website, you consent to our and third party use of cookies as described in our privacy and cookie policy. <a href="#"
      routerLink="/policies/privacy-and-cookie" target="_blank">More info</a>. <a class="cookieConsentOK">That's Fine</a>
  </div> -->
</footer>
<!--================ End footer Area  =================-->