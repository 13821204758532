<div class="container">
  <h3 class="text-primary ab_left_text">AML / CFT RISK BASED REPORT (RBS)
  </h3>
  <blockquote class="generic-blockquote">Compliance Planet generates and export the monthly AML/CFT RBS reports to
    excel within 20 mins after schedule.
  </blockquote><br />

  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
        aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
            aria-controls="collapseOne">
            WORKFLOW ARCHITECTURE
          </button>
        </h5>
      </div>

      <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image11.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>

  </div>

  <br />
  <blockquote class="generic-blockquote">
    The Compliance Planet Intelligent Artificial Service for AML/CFT RBS spread across various data repositories for
    all departments in the institution to routinely spool, govern and prepare report data pertaining to the
    sections/sectors below:
  </blockquote>
  <br />
  <ol>
    <li>
      Customer Type
    </li>
    <li>
      Size Of Transactions (Deposits)
    </li>
    <li>
      Inherent Risk: Higher Risk Customers, Products, Services, Geographic Zones And Delivery Channels
    </li>
    <li>
      Customer
    </li>
    <li>
      Peps
    </li>
    <li>
      Public Sector Clients
    </li>
    <li>
      NGOs/NPOs
    </li>
    <li>
      Oil And Gas Companies
    </li>
    <li>
      Importers And Exporters (Non oil & Gas)
    </li>
    <li>
      Nonresident Legal Persons & Arrangements
    </li>
    <li>
      Nonresident Individuals
    </li>
    <li>
      Money Service Business
    </li>
    <li>
      Store Value Cards
    </li>
    <li>
      High Value Business
    </li>
    <li>
      Securities Brokers And Agents, Mutual Funds Managers/Administrators
    </li>
    <li>
      Lawyers, Trustees, Accountants & Other Professionals
    </li>
    <li>
      Real Estate Developers, Brokers & Agents
    </li>
    <li>
      Products And Services
    </li>
    <li>
      Cash Secured Credits
    </li>
    <li>
      Sale Of Monetary Instruments
    </li>
    <li>
      Inwards Wire Transfers
    </li>
    <li>
      Outwards Wire Transfers
    </li>
    <li>
      Correspondent Banking
    </li>
    <li>
      Private Banking Services
    </li>
    <li>
      Trusts
    </li>
    <li>
      Delivery Risk
    </li>
    <li>
      Internet/E-banking
    </li>
    <li>
      Phone And Mobile Banking
    </li>
    <li>
      Other Non Face to face Services (ATM)
    </li>
    <li>
      AML/CFT Reports Filed
    </li>
    <li>
      Other Reports
    </li>
    <li>
      AML/CFT Penalties & Fines Imposed By Regulatory Authorities, Courts, Etc
    </li>
  </ol>

</div>