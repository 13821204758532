<div class="container">
  <h3 class="text-primary ab_left_text">DATA PANEL
  </h3>
  <p>
    The data panel is the control and integrity of compliance planet. It houses features that provide information on
    all individual/corporate accounts, corporate account directors, branch codes, miscellaneous accounts, SWIFT
    schemes, Account schemes and their tier tags, customer sector tier, sub sector schemes, report exempted accounts,
    capital importation certificates, report person, institution asset and so many more.
    The data panel section comprises of;

  </p>
  <blockquote class="generic-blockquote">
    <span class="text-primary">Miscellaneous Accounts - </span><br />
    These are suspense accounts used in transmitting funds between parties. Since they do not
    possess all the report schema details like customers account by default, Compliance Planet offers this features for
    the compliance team to structure these accounts as the report schema demands only when they must be involved in
    reports.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Customer Accounts - </span><br />
    Compliance planet offers info-pool feature for quick viewing of customers account and
    extraction of data.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Corporate Account Directors - </span><br />
    Compliance planet holds a repository of all Corporate Account Directors and monitors
    the constant update of information. It also offers Data Visualization for Business intelligence into corporate
    accounts partaking in CTR FTR threshood transactions without director information.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">SWIFT Scheme - </span><br />
    This is a repository of all SWIFT scheme of financial institutions with modules for CRUD operations.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Account Scheme - </span><br />
    This is a repository of all financial products in the institutions with modules for CRUD
    operations and mapping with ADRAPS Customer Type etc.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Account Tier - </span><br />
    This is a repository of all account scheme in the financial institutions with modules for
    categorization into Tiers. This is a requirement for accuracy and integrity in ADRAPS KYC report.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Customer Sector Tier - </span><br />
    This is a repository of all Sub-Sectors in the institutions with modules for CRUD operations
    and mapping with Customer Sector Category for AML/CFT RBS Report.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Sub - Sector Scheme - </span><br />
    This is a repository of all Sub-Sectors in the institutions with modules for CRUD operations
    and mapping with ADRAPS line of business code. During the processing of the various ADRAPS reports, the compliance
    planet processes the sub-sector code and line of business code in order to place each account under a line of
    business in accordance with ADRAPS requirements.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Exempted Account - </span><br />
    This is a repository for accounts that may for one reason or the other be exempted in reports.
    There are features for CRUD operations.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Exempted Account Scheme - </span><br />
    Exempted Account Scheme - This is a repository for account schemes that may for one reason or the other be exempted
    in reports. There are features for CRUD operations.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Certificate Of Capital Importation - </span><br />
    This is a repository for CCI in lieu of FXFT related transactions and tools to
    map these CCIs to these transactions. This is a requirement for the accuracy of ADRAPS FTR.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Branch Codes - </span><br />
    This is a repository for all branches of the institution with features to set them active or
    inactive as may be required for the proper reporting of AML CFT RBS report.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Report Person Data - </span><br />
    This is a feature to set the report person data (i.e. Chief Compliance Officer) information
    that is needed for Compliance Regulatory Reports.
  </blockquote><br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Institution Data - </span><br />
    This is a feature to set the institution parameters(i.e. Full name, Acronym, Address,
    Institution Code etc) as may be required in Compliance Regulatory Reports.
  </blockquote><br />

</div>