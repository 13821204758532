import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security-manager',
  templateUrl: './security-manager.component.html',
  styleUrls: ['./security-manager.component.css']
})
export class SecurityManagerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
