import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-compliance-planet',
  templateUrl: './product-compliance-planet.component.html',
  styleUrls: ['./product-compliance-planet.component.css']
})
export class ProductCompliancePlanetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
