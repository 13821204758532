import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Constant from './Constant';
import { IGeneric } from '../Interfaces/igeneric';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IDeviceProp } from '../Interfaces/idevice-prop';
import { IResp } from '../Interfaces/iresp';
import { GlobalVariablesService } from './global-variables.service';
@Injectable({
  providedIn: 'root'
})
export class GenericDataService {

  constructor(private http: HttpClient, private deviceService: DeviceDetectorService,
    private gVS: GlobalVariablesService) { }
  GetCountryList(): Observable<IGeneric[]> {
    try {
      let uri = Constant.Url_CountryList;
      return this.http
        .get<IGeneric[]>(uri);
    } catch (err) {
      console.log(err);
    }
  }
  SetCountryList() {
    try {
      this.GetCountryList()
        .subscribe(r => { this.gVS.SetCountryList(r) });
    } catch (err) {
      console.log(err);
    }
  }
  DeviceInfo(): Observable<IResp> {
    let DeviceModel = {} as IDeviceProp;
    //this.deviceInfo = this.deviceService.getDeviceInfo();
    DeviceModel.isMobile = this.deviceService.isMobile();
    DeviceModel.isTablet = this.deviceService.isTablet();
    DeviceModel.isDesktop = this.deviceService.isDesktop();
    DeviceModel.browser = this.deviceService.browser;
    DeviceModel.browser_version = this.deviceService.browser_version;
    DeviceModel.device = this.deviceService.device;
    DeviceModel.os = this.deviceService.os;
    DeviceModel.os_version = this.deviceService.os_version;
    DeviceModel.userAgent = this.deviceService.userAgent;
    DeviceModel.entryPlatform = 'Efuelite Solution Site';

    this.gVS.SetDeviceProperty(DeviceModel);
    try {
      let uri = Constant.Url_Device_Property_Save;
      return this.http
        .post<IResp>(uri, DeviceModel);
    } catch (err) {
      console.log(err);
    }

  }
}
