<section class="team_area p_120">
  <div class="container">
    <div class="main_title">
      <h2>Meet Our Top Experts</h2>
      <p>who are in extreme love with the eco friendly system.</p>
    </div>
    <div class="row team_inner">
      <div class="col-lg-4 col-sm-6">
        <div class="team_item">
          <div class="team_img">
            <img class="img-fluid team-img" height="250px;" width="200px;" src="../assets/img/team/prince_efue.png"
              alt="Prince Efue">
            <div class="hover">
              <a href="https://web.facebook.com/efue.prince" target="_blank"><i class="fa fa-facebook"></i></a>

              <a href="" target="_blank"><i class="fa fa-twitter"></i></a>
              <a href="https://www.linkedin.com/in/princeefue/" target="_blank"><i class="fa fa-linkedin"></i></a>
            </div>
          </div>
          <div class="team_name">
            <h4>Dr. Prince Efue</h4>
            <p>Founder, CEO</p>
            <p><a href="https://tawk.to/prince" target="_blank"><i class="fa fa-comments"></i>&nbsp;Chat</a>
              &nbsp;&nbsp; &nbsp;<a href="mailto:prince.efue@efuelite.com" target="_blank"><i
                  class="fa fa-envelope-o"></i>&nbsp;
                prince.efue@efuelite.com</a></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="team_item">
          <div class="team_img">
            <img class="img-fluid team-img" height="250px;" width="200px;" src="../assets/img/team/bamidele_lawal.png"
              alt="Bamidele Lawal">
            <div class="hover">
              <a href="https://web.facebook.com" target="_blank"><i class="fa fa-facebook"></i></a>
              <a href=""><i class="fa fa-twitter"></i></a>
              <a href="https://www.linkedin.com" target="_blank"><i class="fa fa-linkedin"></i></a>
            </div>
          </div>
          <div class="team_name">
            <h4>Bamidele Lawal</h4>
            <p>Chief Technical Officer</p>
            <p><a href="https://tawk.to" target="_blank"><i class="fa fa-comments"></i>&nbsp;Chat</a>
              &nbsp;&nbsp; &nbsp;<a href="mailto:bamidele.lawal@efuelite.com" target="_blank"><i
                  class="fa fa-envelope-o"></i>&nbsp;
                bamidele.lawal@efuelite.com</a></p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="team_item">
          <div class="team_img">
            <img class="img-fluid team-img" height="250px;" width="200px;" src="../assets/img/team/shade_efue.png"
              alt="Shade Prince Efue">
            <div class="hover">
              <a href="https://web.facebook.com/ogunsijj" target="_blank"><i class="fa fa-facebook"></i></a>
              <a href="" target="_blank"><i class="fa fa-twitter"></i></a>
              <a href="https://www.linkedin.com/in/ogunsiji-shade-a460a9138/" target="_blank"><i
                  class="fa fa-linkedin"></i></a>
            </div>
          </div>
          <div class="team_name">
            <h4>Shade Prince Efue</h4>
            <p>Co-founder, CFO</p>
            <p><a href="https://tawk.to/shade" target="_blank"><i class="fa fa-comments"></i>&nbsp;Chat</a>
              &nbsp;&nbsp; &nbsp;<a href="mailto:shade.efue@efuelite.com" target="_blank"><i
                  class="fa fa-envelope-o"></i>&nbsp;
                shade.efue@efuelite.com</a></p>
          </div>
        </div>
      </div>



    </div>
  </div>
</section>







<!-- 
<div class="container team-section">
  <div class="main_title">
    <h2>Meet Our Top Experts</h2>
    <p>who are in extreme love with the eco friendly system.</p>
  </div>
  <div class="row">
    
    <div class="col s4 l3 xl3 animated bounceIn">
      <div class="team-box">
        <div class="img-box">
          <img class="img-fluid " height="300px;" width="250px;" src="../assets/img/team/Shade.PNG"
            alt="Shade Prince Efue">
          <div id="team_shape-1" class="team-shape" style="clip-path: url(&quot;#team-1&quot;);">
            <svg width="100%" height="100%">
              <clipPath id="team-1" clipPathUnits="objectBoundingBox">
                <polygon points="0 0, 0 1, 1 0.93, 0 0"></polygon>
              </clipPath>
            </svg>
          </div>
          <div class="team-hover-box">
            <ul>
              <li><a class="facebook" href="https://web.facebook.com/ogunsijj" target="_blank"><i
                    class="fa fa-facebook"></i></a></li>
              <li><a class="twitter" href="" target="_blank"><i class="fa fa-twitter"></i></a></li>
              <li><a class="linkedin" href="https://www.linkedin.com/in/ogunsiji-shade-a460a9138/" target="_blank"><i
                    class="fa fa-linkedin"></i></a>
              </li>
            </ul>
            <p> Grow your career by joining the Efuelite Community !!!</p>
          </div>
        </div>
        <h3>Shade Prince Efue <i>(ACA)</i></h3>
        <p>Managing Director (Finance)</p>
        <p><a href="https://tawk.to/shade" target="_blank"><i class="fa fa-comments"></i>&nbsp;Chat</a>
          &nbsp;&nbsp; &nbsp;<a href="mailto:shade.efue@efuelite.com" target="_blank"><i
              class="fa fa-envelope-o"></i>&nbsp;
            shade.efue@efuelite.com</a></p>
      </div>
    </div>
    <div class="col s4 l3 xl3 animated bounceIn">
      <div class="team-box">
        <div class="img-box">
          <img class="img-fluid " height="300px;" width="250px;" src="../assets/img/team/Timilehin.PNG"
            alt="Timilehin Joy Adewale">
          <div id="team_shape-1" class="team-shape" style="clip-path: url(&quot;#team-1&quot;);">
            <svg width="100%" height="100%">
              <clipPath id="team-1" clipPathUnits="objectBoundingBox">
                <polygon points="0 0, 0 1, 1 0.93, 0 0"></polygon>
              </clipPath>
            </svg>
          </div>
          <div class="team-hover-box">
            <ul>
              <li><a class="facebook" href="https://web.facebook.com/adewale.joy" target="_blank"><i
                    class="fa fa-facebook"></i></a></li>
              <li><a class="twitter" href="" target="_blank"><i class="fa fa-twitter"></i></a></li>
              <li><a class="linkedin" href="https://www.linkedin.com/in/timilehin-adewale-56225b11b/"
                  target="_blank"><i class="fa fa-linkedin"></i></a>
              </li>
            </ul>
            <p> Grow your career by joining the Efuelite Community !!!</p>
          </div>
        </div>
        <h3>Timilehin Joy Adewale <i>(ACA)</i></h3>
        <p>Managing Director (Compliance Product)</p>
        <p><a href="https://tawk.to/timilehin" target="_blank"><i class="fa fa-comments"></i>&nbsp;Chat</a>
          &nbsp;&nbsp; &nbsp;<a href="mailto:timilehin.adewale@efuelite.com" target="_blank"><i
              class="fa fa-envelope-o"></i>&nbsp;
            timilehin.adewale@efuelite.com</a></p>
      </div>
    </div>
    <div class="col s4 l3 xl3 animated bounceIn">
      <div class="team-box">
        <div class="img-box">
          <img class="img-fluid " height="300px;" width="250px;" src="../assets/img/team/Prince.PNG" alt="Prince Efue">
          <div id="team_shape-1" class="team-shape" style="clip-path: url(&quot;#team-1&quot;);">
            <svg width="100%" height="100%">
              <clipPath id="team-1" clipPathUnits="objectBoundingBox">
                <polygon points="0 0, 0 1, 1 0.93, 0 0"></polygon>
              </clipPath>
            </svg>
          </div>
          <div class="team-hover-box">
            <ul>
              <li><a class="facebook" href="https://web.facebook.com/efue.prince" target="_blank"><i
                    class="fa fa-facebook"></i></a></li>
              <li><a class="twitter" href="" target="_blank"><i class="fa fa-twitter"></i></a></li>
              <li><a class="linkedin" href="https://www.linkedin.com/in/princeefue/" target="_blank"><i
                    class="fa fa-linkedin"></i></a>
              </li>
            </ul>
            <p> Grow your career by joining the Efuelite Community !!!</p>
          </div>
        </div>
        <h3>Prince Efue <i>(FIMC, CMC)</i></h3>
        <p>Group Head (Information Technology)</p>
        <p><a href="https://tawk.to/prince" target="_blank"><i class="fa fa-comments"></i>&nbsp;Chat</a>
          &nbsp;&nbsp; &nbsp;<a href="mailto:prince.efue@efuelite.com" target="_blank"><i
              class="fa fa-envelope-o"></i>&nbsp;
            prince.efue@efuelite.com</a></p>
      </div>
    </div>
  </div>

</div> -->