<div class="blog_right_sidebar">
  <aside class="single_sidebar_widget search_widget">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Search">
      <span class="input-group-btn">
        <button class="btn btn-default" type="button"><i class="lnr lnr-magnifier"></i></button>
      </span>
    </div><!-- /input-group -->
    <div class="br"></div>
  </aside>
  <!-- <aside class="single_sidebar_widget author_widget">
      <img class="author_img rounded-circle" src="img/blog/author.png" alt="">
      <h4>Charlie Barber</h4>
      <p>Senior blog writer</p>
      <div class="social_icon">
        <a href="#"><i class="fa fa-facebook"></i></a>
        <a href="#"><i class="fa fa-twitter"></i></a>
        <a href="#"><i class="fa fa-github"></i></a>
        <a href="#"><i class="fa fa-behance"></i></a>
      </div>
      <p>Boot camps have its supporters andit sdetractors. Some people do not understand why you should have to
        spend money on boot camp when you can get. Boot camps have itssuppor ters andits detractors.</p>
      <div class="br"></div>
    </aside> -->
  <!-- <aside class="single_sidebar_widget popular_post_widget">
      <h3 class="widget_title">Popular Posts</h3>
      <div class="media post_item">
        <img src="img/blog/popular-post/post1.jpg" alt="post">
        <div class="media-body">
          <a href="blog-details.html">
            <h3>Space The Final Frontier</h3>
          </a>
          <p>02 Hours ago</p>
        </div>
      </div>
      <div class="media post_item">
        <img src="img/blog/popular-post/post2.jpg" alt="post">
        <div class="media-body">
          <a href="blog-details.html">
            <h3>The Amazing Hubble</h3>
          </a>
          <p>02 Hours ago</p>
        </div>
      </div>
      <div class="media post_item">
        <img src="img/blog/popular-post/post3.jpg" alt="post">
        <div class="media-body">
          <a href="blog-details.html">
            <h3>Astronomy Or Astrology</h3>
          </a>
          <p>03 Hours ago</p>
        </div>
      </div>
      <div class="media post_item">
        <img src="img/blog/popular-post/post4.jpg" alt="post">
        <div class="media-body">
          <a href="blog-details.html">
            <h3>Asteroids telescope</h3>
          </a>
          <p>01 Hours ago</p>
        </div>
      </div>
      <div class="br"></div>
    </aside> -->
  <aside class="single_sidebar_widget ads_widget">
    <a href="#" routerLink=""><img class="img-fluid" src="../assets/img/EfueliteSolutions_Logo.png" alt=""></a>
    <div class="br"></div>
  </aside>
  <aside class="single_sidebar_widget post_category_widget">
    <h4 class="widget_title">OUR SERVICES</h4>
    <ul class="list cat-list">
      <li>
        <a href="#" routerLink="/services/mobile-app-development" class="d-flex justify-content-between">
          <p>Mobile App Development</p>
          <!-- <p>37</p> -->
        </a>
      </li>
      <li>
        <a href="#" routerLink="/services/web-app-development" class="d-flex justify-content-between">
          <p>Web App Development</p>
          <!-- <p>37</p> -->
        </a>
      </li>
      <li>
        <a href="#" routerLink="/services/it-consultancy" class="d-flex justify-content-between">
          <p>IT Consultancy</p>
          <!-- <p>37</p> -->
        </a>
      </li>
      <li>
        <a href="#" routerLink="/services/digital-marketing" class="d-flex justify-content-between">
          <p>Digital Marketing</p>
          <!-- <p>37</p> -->
        </a>
      </li>
      <li>
        <a href="#" routerLink="/services/business-modelling" class="d-flex justify-content-between">
          <p>Business Modelling</p>
          <!-- <p>37</p> -->
        </a>
      </li>
      <li>
        <a href="#" routerLink="/services/training" class="d-flex justify-content-between">
          <p>Training</p>
          <!-- <p>37</p> -->
        </a>
      </li>
      <li>
        <a href="#" routerLink="/services/professionals" class="d-flex justify-content-between">
          <p>Our Professional Services</p>
          <!-- <p>37</p> -->
        </a>
      </li>
      <li>
        <a href="#" routerLink="/services/support" class="d-flex justify-content-between">
          <p>Our Support Services</p>
          <!-- <p>37</p> -->
        </a>
      </li>
      <li>
        <a href="#" routerLink="/services/technical" class="d-flex justify-content-between">
          <p>Our Technical Services</p>
          <!-- <p>37</p> -->
        </a>
      </li>
    </ul>
    <!-- <div class="br"></div> -->
  </aside>
  <!-- <aside class="single-sidebar-widget newsletter_widget">
      <h4 class="widget_title">Newsletter</h4>
      <p>
        Here, I focus on a range of items and features that we use in life without
        giving them a second thought.
      </p>
      <div class="form-group d-flex flex-row">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i></div>
          </div>
          <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="Enter email" onfocus="this.placeholder = ''"
            onblur="this.placeholder = 'Enter email'">
        </div>
        <a href="#" class="bbtns">Subcribe</a>
      </div>
      <p class="text-bottom">You can unsubscribe at any time</p>
      <div class="br"></div>
    </aside> -->
  <!-- <aside class="single-sidebar-widget tag_cloud_widget">
      <h4 class="widget_title">Tag Clouds</h4>
      <ul class="list">
        <li><a href="#">Technology</a></li>
        <li><a href="#">Fashion</a></li>
        <li><a href="#">Architecture</a></li>
        <li><a href="#">Fashion</a></li>
        <li><a href="#">Food</a></li>
        <li><a href="#">Technology</a></li>
        <li><a href="#">Lifestyle</a></li>
        <li><a href="#">Art</a></li>
        <li><a href="#">Adventure</a></li>
        <li><a href="#">Food</a></li>
        <li><a href="#">Lifestyle</a></li>
        <li><a href="#">Adventure</a></li>
      </ul>
    </aside> -->
</div>