<section class="services_area p_120" id="services_areas">
    <div class="container">
        <div class="main_title">
            <h2>Some Media References</h2>
        </div>
        <div class="row services_inner">
            <div class="col-lg-4">
                <a href="https://guardian.ng/news/how-compliance-planet-software-curbed-money-laundering-and-terrorism-financing-in-nigeria-prince-efue/"
                    target="_blank">

                    <div class="services_item">
                        <span class="custom-icon">
                            <img src="../assets/img/icon/round_airplay_black_48dp.png"
                                alt="Anti-Money Laundering Software">
                        </span>
                        <p>How Compliance Planet Anti-Money Laundering Software curbed money laundering and terrorism
                            financing in Nigeria.
                            <br>
                            See More ...

                        </p>
                    </div>

                </a>
            </div>
            <div class="col-lg-4">
                <a href="https://www.vanguardngr.com/2023/04/nigerian-ict-expert-prince-efue-advocates-for-ai-in-fraud-prevention/"
                    target="_blank">

                    <div class="services_item">
                        <span class="custom-icon">
                            <img src="../assets/img/icon/round_airplay_black_48dp.png"
                                alt="Anti-Money Laundering Software">
                        </span>
                        <p>Compliance Planet Anti-Money Laundering Software leverages artificial intelligence and
                            machine learning to prevent fraud.
                            <br>
                            See More ...

                        </p>
                    </div>

                </a>
            </div>
            <div class="col-lg-4">
                <a href="https://www.thisdaylive.com/index.php/2023/01/23/thisdayprince-efue-ict-expert-developing-innovative-business-solutions-in-nigeria"
                    target="_blank">

                    <div class="services_item">
                        <span class="custom-icon">
                            <img src="../assets/img/icon/round_airplay_black_48dp.png"
                                alt="Anti-Money Laundering Software">
                        </span>
                        <p>Efuelite Solutions innovative softwares
                            perfectly fit into business models and processes, enhancing productivity, and service
                            quality.
                            <br>
                            See More ...

                        </p>
                    </div>

                </a>
            </div>

        </div>

    </div>
</section>