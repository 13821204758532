<!--================Home Banner Area =================-->
<section class="banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
      <div class="banner_content text-center">
        <div class="page_link">
          <a href="#" routerLink="">Home</a>
          <a href="#" routerLink="/about-us">About Us</a>
        </div>
        <h2>About Us</h2>
      </div>
    </div>
  </div>
</section>
<!--================End Home Banner Area =================-->
<section class="blog_area single-post-area p_120">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 posts-list">
        <div class="ab_left_text">
          <h4>About Us <br />Our History</h4>
          <p>Efuelite Solutions Limited. is a company based in Nigeria and founded on standard enterprise
            industrial
            principles, customer service, quality and trust. Efuelite Solutions President, Prince Efue, started
            the
            company in 2006, got registered as buisiness in 2016 and upgraded to private limited liability company in
            2019
            as subsidiary under <a href="http://www.efuelite.com" target="_blank">Efuelite
              Group LTD.</a> and charted the growth of
            product lines and services while maintaining those solid core principles. Our can-do team of
            engineers and
            technical staff consistently incorporate new and innovative ideas into our products. In short, we
            push the
            envelope, raising the bar in innovation and quality. Over time, Efuelite Solutions has become the
            industry
            standard in the bespoke software development market.

            Efuelite Solutions continuously develops new,
            <a href="#" routerLink="/products/compliance-planet/introduction" target="_blank">cutting-edge products</a>
            , creating solutions for our customers around the world in industries across
            the
            board. Our growing network of distributors has expanded our presence to markets far beyond our home
            headquarters in Lagos; our products are now sold in africa and european countries around the world.
            Our
            employees are committed to quality and customer satisfaction. Behind the full range of Efuelite
            Solutions
            products and services is the cumulative expertise of experienced engineers, manufacturing and
            gadget
            professionals and technicians. Our knowledgeable sales team can offer technical advice to assist in
            selecting the right product for each application, as well as providing after-sales support.
            Efuelite
            Solutions is dedicated to providing customers with reliable, affordable products, hassle-free
            ordering and
            excellent service, saving customers time and money. It is our goal to earn your trust in meeting
            your needs
            and providing innovative solutions. The products and services that bear the Efuelite Solutions name
            come
            with quality assurance and the best support in the industry today.
          </p>
          <h4>Vision</h4>
          <p>To be the leader in providing cutting edge business solutions that surpasses bottlenecks in
            technology</p>
          <h4>Mission</h4>
          <p>To increase efficiency in business processes and automation.</p>
          <h4>Why choose us</h4>
          <p>Efuelite IT services span the complete life cycle, its true value is seen from the very early
            stages with
            high level consulting directing the project as it matures into detailed project designs and
            implementation.
            At Efuelite Solutions, we understand that businesses need quality and well-planned IT systems to
            prevent
            downtime. We develop reliable, long-term IT solutions which limit ongoing and unnecessary expenses
            associated with quick fix products. We plan with our clients and go the extra step to ensure that
            IT
            systems we create keep up with the needs of growing businesses.
            We are dedicated to Educate, Empower and Enable all through the application of new and emerging
            technologies in IT.
            We help bridge the digital divide by offering our clients convenient and reliable solutions guided
            by the
            principle of low-cost, high value service provision through the application of innovative methods
            and
            technologies.
            We develop and maintain comprehensive information repositories on systems, processes and tools
            across
            projects process specific training programs to maximize domain expertise resulting in scalability
            and
            consistency in quality.
            Our approach is simple - we refuse to compromise when it comes to quality. We set our standards
            high and
            then we aim to surpass them. We do this by investing in the continuous improvement of our products
            and
            services by building a team that is knowledgeable and dedicated to ensuring that we exceed the
            needs of our
            customers.
            We are committed to reliability in all that we do. Reliable products. Reliable service. Reliable
            relationships. We design our products to work under demanding conditions by putting them through
            rigorous
            testing. Our continuous communication with our customers allows us to adapt and grow with their
            requirements.</p>
          <!-- <a class="ab_btn" href="#">Request Custom Price</a> -->
        </div>
      </div>
      <div class="col-lg-4">
        <app-right-side-quick-link></app-right-side-quick-link>
      </div>
    </div>
  </div>
</section>


<!--================Team Area =================-->
<app-expert-team></app-expert-team>
<!--================End Team Area =================-->