<div class="container">
  <h3 class="text-primary ab_left_text">ADRAPS POLITICALLY EXPOSED PERSONS REPORT (ADRAPS PEP)
  </h3>

  <blockquote class="generic-blockquote">Compliance Planet generates and export the monthly ADRAPS PEP reports to excel
    and XML formats within 20 mins after schedule.
    Aside report production for PEP, the software also has repository features to manage PEP information for the
    institution as well as operation desks for PEP application to approval stage.
  </blockquote><br />

  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
      aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
            aria-controls="collapseOne">
            WORKFLOW ARCHITECTURE
          </button>
        </h5>
      </div>

      <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image1.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
  </div>
</div>