<div class="container">
  <h3 class="text-primary ab_left_text">System Requirements
  </h3>
  <blockquote class="generic-blockquote">
    Efuelite Solutions Compliance Planet Software requires a server PC with the following minimum requirements:
  </blockquote><br />
  <ul>
    <li>
      Operating System: Windows Server 2012 R2 Standard 64-bit (6.3, Build 9600) (9600.winblue_ltsb_escrow.180302-1800)
    </li>
    <li>
      Language: English (Regional Setting: English)
    </li>
    <li>
      System Manufacturer: Microsoft Corporation
    </li>
    <li>
      System Model: Virtual Machine
    </li>
    <li>
      Processor: Intel(R) Xeon(R) CPU E7- 4807 @ 1.87GHz (16 CPUs), ~1.9GHz
    </li>
    <li>
      Memory: 32096MB RAM
    </li>
    <li>
      1TB Hard Disk Space
    </li>
    <i>(Note: Although the software is designed to work with the Windows Operating Systems listed above, Efuelite
      Solutions cannot guarantee operation on OS’s no longer supported by Microsoft Support Lifecycle Policy.) </i>
  </ul>
</div>