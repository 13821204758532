import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { MobileAppDevelopmentComponent } from './mobile-app-development/mobile-app-development.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { ITConsultancyComponent } from './itconsultancy/itconsultancy.component';
import { TrainingComponent } from './training/training.component';
import { BusinessModellingComponent } from './business-modelling/business-modelling.component';
import { ProfessionalServicesComponent } from './professional-services/professional-services.component';
import { SupportServicesComponent } from './support-services/support-services.component';
import { TechnicalServicesComponent } from './technical-services/technical-services.component';
import { CareerComponent } from './career/career.component';
import { ProductCompliancePlanetComponent } from './product-compliance-planet/product-compliance-planet.component';
import { IntroductionComponent } from './Products/CompliancePlanet/introduction/introduction.component';
import { CTRFTRComponent } from './Products/CompliancePlanet/ctrftr/ctrftr.component';
import { InstallationComponent } from './Products/CompliancePlanet/installation/installation.component';
import { SystemRequirementsComponent } from './Products/CompliancePlanet/system-requirements/system-requirements.component';
import { FileDockComponent } from './Products/CompliancePlanet/file-dock/file-dock.component';
import { TaskManagerComponent } from './Products/CompliancePlanet/task-manager/task-manager.component';
import { SecurityManagerComponent } from './Products/CompliancePlanet/security-manager/security-manager.component';
import { ServiceManagerComponent } from './Products/CompliancePlanet/service-manager/service-manager.component';
import { DataPanelComponent } from './Products/CompliancePlanet/data-panel/data-panel.component';
import { TransactionMonitoringComponent } from './Products/CompliancePlanet/transaction-monitoring/transaction-monitoring.component';
import { FATCAComponent } from './Products/CompliancePlanet/fatca/fatca.component';
import { AMLCFTRBSComponent } from './Products/CompliancePlanet/amlcftrbs/amlcftrbs.component';
import { ADRAPSKYCComponent } from './Products/CompliancePlanet/adrapskyc/adrapskyc.component';
import { ADRAPSPEPComponent } from './Products/CompliancePlanet/adrapspep/adrapspep.component';
import { ADRAPSFTRComponent } from './Products/CompliancePlanet/adrapsftr/adrapsftr.component';
import { STRSARComponent } from './Products/CompliancePlanet/strsar/strsar.component';
import { PolicyPrivacyAndCookieComponent } from './policy-privacy-and-cookie/policy-privacy-and-cookie.component';

const routes: Routes = [{
  path: '',//, redirectTo: 'about', pathMatch: 'full'
  component: HomeComponent
},
{
  path: 'home',
  component: HomeComponent
},
{
  path: 'about-us',
  component: AboutUsComponent
}, {
  path: 'contact-us',
  component: ContactComponent
}, {
  path: 'careers',
  component: CareerComponent
}, {
  path: 'policies/privacy-and-cookie',
  component: PolicyPrivacyAndCookieComponent
}, {
  path: 'services/mobile-app-development',
  component: MobileAppDevelopmentComponent
}, {
  path: 'services/web-app-development',
  component: WebDevelopmentComponent
}, {
  path: 'services/digital-marketing',
  component: DigitalMarketingComponent
}, {
  path: 'services/it-consultancy',
  component: ITConsultancyComponent
}, {
  path: 'services/training',
  component: TrainingComponent
}, {
  path: 'services/business-modelling',
  component: BusinessModellingComponent
}, {
  path: 'services/professionals',
  component: ProfessionalServicesComponent
}, {
  path: 'services/support',
  component: SupportServicesComponent
}, {
  path: 'services/technical',
  component: TechnicalServicesComponent
}, {
  path: 'products/compliance-planet',
  component: ProductCompliancePlanetComponent, children: [
    {
      path: '', pathMatch: 'full', redirectTo: 'introduction'
    },
    {
      path: 'introduction',
      runGuardsAndResolvers: 'always',
      component: IntroductionComponent
    }, {
      path: 'currency-foreign-transaction-report-CTR-FTR',
      runGuardsAndResolvers: 'always',
      component: CTRFTRComponent
    }, {
      path: 'suspicious-transaction-activity-report-STR-SAR',
      runGuardsAndResolvers: 'always',
      component: STRSARComponent
    }, {
      path: 'adraps-foreign-transaction-report-ADRAPS-FTR',
      runGuardsAndResolvers: 'always',
      component: ADRAPSFTRComponent
    }, {
      path: 'adraps-politically-exposed-person-report-ADRAPS-PEP',
      runGuardsAndResolvers: 'always',
      component: ADRAPSPEPComponent
    }, {
      path: 'adraps-3-tiered-know-your-customer-report-ADRAPS-KYC',
      runGuardsAndResolvers: 'always',
      component: ADRAPSKYCComponent
    }, {
      path: 'anti-money-laundering-combating-financing-of-terrorism-risk-base-supervision-report-AML-CFT-RBS',
      runGuardsAndResolvers: 'always',
      component: AMLCFTRBSComponent
    }, {
      path: 'foreign-account-tax-compliance-act-FATCA',
      runGuardsAndResolvers: 'always',
      component: FATCAComponent
    }, {
      path: 'transaction-activity-monitoring',
      runGuardsAndResolvers: 'always',
      component: TransactionMonitoringComponent
    }, {
      path: 'data-panel',
      runGuardsAndResolvers: 'always',
      component: DataPanelComponent
    }, {
      path: 'service-manager',
      runGuardsAndResolvers: 'always',
      component: ServiceManagerComponent
    }, {
      path: 'security-manager',
      runGuardsAndResolvers: 'always',
      component: SecurityManagerComponent
    }, {
      path: 'task-manager',
      runGuardsAndResolvers: 'always',
      component: TaskManagerComponent
    }, {
      path: 'file-dock',
      runGuardsAndResolvers: 'always',
      component: FileDockComponent
    }, {
      path: 'system-requirements',
      runGuardsAndResolvers: 'always',
      component: SystemRequirementsComponent
    }, {
      path: 'installations',
      runGuardsAndResolvers: 'always',
      component: InstallationComponent
    }, {
      path: '**',
      component: IntroductionComponent
    }]
}, {
  path: '**',
  component: HomeComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
