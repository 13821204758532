<div class="container">
  <h3 class="text-primary ab_left_text">ADRAPS THREE-TIERED KNOW YOUR CUSTOMER REPORT (ADRAPS KYC)
  </h3>

  <blockquote class="generic-blockquote">Compliance Planet generates and export the monthly ADRAPS KYC reports to excel
    and XML formats within 15 mins after schedule.
    Aside report production for ADRAPS KYC, the software also has Business intelligence feature for data visualization
    on ADRAPS KYC over any time frame.
  </blockquote><br />

  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
        aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
            aria-controls="collapseOne">
            WORKFLOW ARCHITECTURE
          </button>
        </h5>
      </div>

      <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image1.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
  </div>
</div>