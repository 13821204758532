import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import {
  ReactiveFormsModule, FormsModule
} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ActiveJSService } from './Services/active-js.service';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { MobileAppDevelopmentComponent } from './mobile-app-development/mobile-app-development.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { ITConsultancyComponent } from './itconsultancy/itconsultancy.component';
import { TrainingComponent } from './training/training.component';
import { BusinessModellingComponent } from './business-modelling/business-modelling.component';
import { ExpertTeamComponent } from './expert-team/expert-team.component';
import { ProfessionalServicesComponent } from './professional-services/professional-services.component';
import { SupportServicesComponent } from './support-services/support-services.component';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { HttpClientModule } from '@angular/common/http';
// import { HttpModule } from '@angular/http';
import { TechnicalServicesComponent } from './technical-services/technical-services.component';
import { RightSideQuickLinkComponent } from './right-side-quick-link/right-side-quick-link.component';
import { CareerComponent } from './career/career.component';
import { ProductCompliancePlanetComponent } from './product-compliance-planet/product-compliance-planet.component';
import { LeftSideMenuComponent } from './Products/CompliancePlanet/left-side-menu/left-side-menu.component';
import { IntroductionComponent } from './Products/CompliancePlanet/introduction/introduction.component';
import { CTRFTRComponent } from './Products/CompliancePlanet/ctrftr/ctrftr.component';
import { ADRAPSFTRComponent } from './Products/CompliancePlanet/adrapsftr/adrapsftr.component';
import { ADRAPSKYCComponent } from './Products/CompliancePlanet/adrapskyc/adrapskyc.component';
import { ADRAPSPEPComponent } from './Products/CompliancePlanet/adrapspep/adrapspep.component';
import { AMLCFTRBSComponent } from './Products/CompliancePlanet/amlcftrbs/amlcftrbs.component';
import { STRSARComponent } from './Products/CompliancePlanet/strsar/strsar.component';
import { FATCAComponent } from './Products/CompliancePlanet/fatca/fatca.component';
import { TransactionMonitoringComponent } from './Products/CompliancePlanet/transaction-monitoring/transaction-monitoring.component';
import { DataPanelComponent } from './Products/CompliancePlanet/data-panel/data-panel.component';
import { SecurityManagerComponent } from './Products/CompliancePlanet/security-manager/security-manager.component';
import { ServiceManagerComponent } from './Products/CompliancePlanet/service-manager/service-manager.component';
import { TaskManagerComponent } from './Products/CompliancePlanet/task-manager/task-manager.component';
import { FileDockComponent } from './Products/CompliancePlanet/file-dock/file-dock.component';
import { SystemRequirementsComponent } from './Products/CompliancePlanet/system-requirements/system-requirements.component';
import { InstallationComponent } from './Products/CompliancePlanet/installation/installation.component';
import { PolicyPrivacyAndCookieComponent } from './policy-privacy-and-cookie/policy-privacy-and-cookie.component';
import { CookieJSService } from './Services/cookie-js.service';
import { ContactUSService } from './Services/contact-us.service';
import { MarqueeService } from './Services/marquee.service';
import { DialogConfirmComponent } from './Dialogs/dialog-confirm/dialog-confirm.component';
import { AlertService } from './Services/alert.service';
import { MaterialModule } from './app.material.module';
import { GenericDataService } from './Services/generic-data.service';
import { GlobalVariablesService } from './Services/global-variables.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DialogAdvertComponent } from './Dialogs/dialog-advert/dialog-advert.component';
import { ProjectCounterComponent } from './project-counter/project-counter.component';
import { MediaReferenceComponent } from './media-reference/media-reference.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ContactComponent,
        AboutUsComponent,
        MobileAppDevelopmentComponent,
        WebDevelopmentComponent,
        DigitalMarketingComponent,
        ITConsultancyComponent,
        TrainingComponent,
        BusinessModellingComponent,
        ExpertTeamComponent,
        ProfessionalServicesComponent,
        SupportServicesComponent,
        TechnicalServicesComponent,
        RightSideQuickLinkComponent,
        CareerComponent,
        ProductCompliancePlanetComponent,
        LeftSideMenuComponent,
        IntroductionComponent,
        CTRFTRComponent,
        ADRAPSFTRComponent,
        ADRAPSKYCComponent,
        ADRAPSPEPComponent,
        AMLCFTRBSComponent,
        STRSARComponent,
        FATCAComponent,
        TransactionMonitoringComponent,
        DataPanelComponent,
        SecurityManagerComponent,
        ServiceManagerComponent,
        TaskManagerComponent,
        FileDockComponent,
        SystemRequirementsComponent,
        InstallationComponent,
        PolicyPrivacyAndCookieComponent,
        DialogConfirmComponent,
        DialogAdvertComponent,
        ProjectCounterComponent,
        MediaReferenceComponent
    ],
    imports: [
        BrowserModule, BrowserAnimationsModule,
        AppRoutingModule,
        NgProgressModule,
        NgProgressHttpModule,
        NgProgressRouterModule,
        HttpClientModule,MaterialModule,
        ReactiveFormsModule, FormsModule
    ],
    providers: [ActiveJSService, CookieJSService, ContactUSService, MarqueeService, AlertService,
        GenericDataService, GlobalVariablesService, DeviceDetectorService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
