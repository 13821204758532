import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { CookieJSService } from './cookie-js.service';
@Injectable({
  providedIn: 'root'
})
export class ActiveJSService {

  constructor(public cookieJS: CookieJSService) { }
  LoadCustomJS() {
    //navbarFixed
    let nav_offset_top = $('.header_area').height() + 50;
    if ($('.header_area').length) {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= nav_offset_top) {
          $(".header_area").addClass("navbar_fixed");
        } else {
          $(".header_area").removeClass("navbar_fixed");
        }
      });
    };



    //testimonialSlider
    if ($('.testimonial_slider').length) {
      ($('.testimonial_slider') as any).owlCarousel({
        loop: true,
        margin: 30,
        items: 2,
        nav: false,
        autoplay: true,
        dots: true,
        smartSpeed: 1500,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
        }
      })
    }

    if ($('.testi_slider').length) {
      ($('.testi_slider') as any).owlCarousel({
        loop: true,
        margin: 30,
        items: 2,
        nav: true,
        autoplay: false,
        smartSpeed: 1500,
        dots: true,
        navContainer: '.testimonials_area',
        navText: ['<i class="lnr lnr-arrow-up"></i>', '<i class="lnr lnr-arrow-down"></i>'],
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
        }
      })
    }
    //--------Search Form ---------- $( "#mydiv" ).hasClass( "foo" )//
    $("#search-btn").on('click', function () {
      $("body").toggleClass("search-form-open");
      $(".search-form-area").toggleClass("fadeIn");
    });
    $("#searchformbtn").on('click', function () {
      $("body").toggleClass("search-form-open");
      $(".search-form-area").toggleClass("fadeIn");
    });

    // Back to top button
    $(window).scroll(function () {
      if ($(window).scrollTop() > 50) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });
    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500);
      return false;
    });


    /*---------gallery isotope js-----------*/
    if ($('#gallery').length) {
      ($('#gallery') as any).imagesLoaded(function () {
        // images have loaded
        // Activate isotope in container
        ($("#gallery") as any).isotope({
          itemSelector: ".gallery_item",
          layoutMode: 'masonry',
          animationOptions: {
            duration: 750,
            easing: 'linear'
          }
        });
      })
    }

    //$(document).ready(function () {
    if (!this.cookieJS.isCookieSet('efuelitesolutionscookie_policy')) {
      setTimeout(function () {
        $("#cookieConsent").fadeIn(5000);
      }, 4000);
      $("#closeCookieConsent, .cookieConsentOK").click(function () {
        $("#cookieConsent").fadeOut(5000);
      });
      this.cookieJS.setCookie('efuelitesolutionscookie_policy', 'accepted', 30);
    }

    /*----------------------------------------------------*/
    /*  Clients Slider
    /*----------------------------------------------------*/
    if ($('.clients_slider').length) {
      ($('.clients_slider') as any).owlCarousel({
        loop: true,
        margin: 30,
        items: 5,
        nav: false,
        autoplay: false,
        smartSpeed: 1500,
        dots: false,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 2,
          },
          575: {
            items: 3,
          },
          768: {
            items: 4,
          },
          992: {
            items: 5,
          }
        }
      })
    }
    /*----------------------------------------------------*/
    /*  Google map js
    /*----------------------------------------------------*/
















  };

}
