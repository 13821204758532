<div class="container">
  <h3 class="text-primary ab_left_text">CURRENCY/ FOREIGN TRANSACTION REPORTS (CTR FTR)
  </h3>
  <p>CTR FTR Report Operations on Compliance Planet
  </p>
  <ul>
    <li>
      Generating Reports
    </li>
    <li>
      Exporting reports to XML and Excel
    </li>
    <li>
      Data Visualization for Business Intelligence
    </li>
  </ul>
  <blockquote class="generic-blockquote">CTR FTR data for all financial products and transaction channels as described
    below are continually spooled
    routinely off peak business operations. Compliance Planet takes between 5 secs - 3 mins to generate and export
    weekly CTR FTR data for any or all of the transaction channels. If reports are exported to excel for maybe manual
    data governance, Compliance Planet has the feature plugin to accept uploaded CTR FTR excel files and convert them
    to XML for upload at the NFIU GoAML portal.
  </blockquote><br />

  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
        aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
            aria-controls="collapseOne">
            WORKFLOW ARCHITECTURE
          </button>
        </h5>
      </div>

      <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image8.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapsePTwo" aria-expanded="false"
        aria-controls="collapseTwo">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsePTwo"
            aria-expanded="false" aria-controls="collapseTwo">
            Compliance Planet CTR FTR Report Operation Interface
          </button>
        </h5>
      </div>
      <div id="collapsePTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image6.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>

  </div>

  <br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">Compliance Planet CTR FTR Channels</span><br />
    NIP (NIBSS INSTANT PAYMENT), NAPS (NIBSS Automated Payment Services), NEFT (National Electronic Funds Transfer),
    CHEQUES, RTGS (Real Time Gross Settlement), REMITA, FXFT (FOREIGN EXCHANGE FOREIGN TRANSACTION), INTERNAL TRANSFER,
    CASH DEPOSITS, CASH WITHDRAWAL, INVESTMENT BOOKING, MANAGER'S CHEQUE
  </blockquote>
  <br />
  <blockquote class="generic-blockquote">
    <span class="text-primary">CTR FTR DATA VISUALIZATION FOR BUSINESS INTELLIGENCE</span><br />
    Beyond reporting, Compliance Planet enables the institution’s compliance team to seamlessly analyse and report the
    performance of the bank across all transaction channels and periods. This is made possible by the Business
    Intelligence Feature in Compliance Planet. Graphical illustrations of the BI - Feature is shown below.
  </blockquote>
  <div class="accordion" id="accordionExamples">
    <div class="card">
      <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
        aria-controls="collapseOne">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
            aria-controls="collapseOne">
            Business Intelligence Demonstration 1
          </button>
        </h5>
      </div>

      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExamples">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image14.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
        aria-controls="collapseTwo">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo"
            aria-expanded="false" aria-controls="collapseTwo">
            Business Intelligence Demonstration 2
          </button>
        </h5>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExamples">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image10.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
        aria-controls="collapseThree">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
            aria-expanded="false" aria-controls="collapseThree">
            Business Intelligence Demonstration 3
          </button>
        </h5>
      </div>
      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExamples">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image5.png" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
        aria-controls="collapseFour">
        <h5 class="mb-0">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour"
            aria-expanded="false" aria-controls="collapseFour">
            Business Intelligence Demonstration 4
          </button>
        </h5>
      </div>
      <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExamples">
        <div class="card-body">
          <img height="400px;" width="600px;" src="/assets/img/cplanet/image16.jpg" alt="Compliance Planet Software by Efuelite Solutions" />
        </div>
      </div>
    </div>
  </div>

</div>