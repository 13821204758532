import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-strsar',
  templateUrl: './strsar.component.html',
  styleUrls: ['./strsar.component.css']
})
export class STRSARComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
