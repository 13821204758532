import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expert-team',
  templateUrl: './expert-team.component.html',
  styleUrls: ['./expert-team.component.css']
})
export class ExpertTeamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
