<!--================Home Banner Area =================-->
<section class="banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div class="overlay2 bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
  </div>
</section>
<!--================End Home Banner Area =================-->

<!--================About Area =================-->
<section class="blog_area single-post-area p_120">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <cplanet-left-side-menu></cplanet-left-side-menu>
      </div>
      <div class="col-lg-9 posts-list">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>





<!--================Team Area =================-->
<app-expert-team></app-expert-team>
<!--================End Team Area =================-->