import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-monitoring',
  templateUrl: './transaction-monitoring.component.html',
  styleUrls: ['./transaction-monitoring.component.css']
})
export class TransactionMonitoringComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
