import { Component } from '@angular/core';

@Component({
  selector: 'app-project-counter',
  templateUrl: './project-counter.component.html',
  styleUrls: ['./project-counter.component.css']
})
export class ProjectCounterComponent {

}
