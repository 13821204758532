import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Constant from './Constant';
import { IResp } from '../Interfaces/iresp';

@Injectable({
  providedIn: 'root'
})
export class MarqueeService {

  constructor(private http: HttpClient) { }
  Marquee_Get(): Observable<IResp> {
    try {
      let uri = Constant.UrlMarquee_Get;
      let params = new HttpParams()
        .set("subsidiary", "EfueliteSolutions")
      return this.http
        .get<IResp>(uri, { params: params });
    } catch (err) {
      console.log(err);
    }
  }
}
